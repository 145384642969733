import React, { Fragment } from 'react'
import { graphql } from 'gatsby'

import { TitleAndMetaTags } from '../components/Helpers.TitleAndMetaTags'
import { Layout } from '../components/Layout'
import { SmallWrapper, FullWidthWrapper } from '../components/Layout.Wrapper'
import { Spacer } from '../components/Layout.Spacer'
import { Image } from '../components/Common.Image'
import { ButtonContainer, GreenButton } from '../components/Common.Button'
import { IdLink } from '../components/Common.IdLink'
import { H3, H4,HugeHeading } from '../components/Typography.Headings'

import { Artists } from '../containers/Artists'

import appleVille from '../../static/assets/appleville_live.png'

import MermaidsLogo from '../../static/assets/MermaidsLogo.svg'
import BCA from '../../static/assets/bca.png'

import ticket from '../../static/assets/ticket.png'

import whiteshirt from '../../static/assets/whiteshirt.png'
import blueshirt from '../../static/assets/blueshirt.png'
import apple from '../../static/assets/apple_mosaic.jpg'

import hifilabs from '../../static/assets/hifilabs.png'

import { VideoContainer } from '../containers/VideoContainer';
import { GifContainer } from '../containers/GifContainer';

export const IndexPageContent = ({ data }) => {

  const { config } = data
  const {
    artistTitle,
    shirtTitle,
    shirtText,
    shirtBuyButtonTextUK,
    shirtBuyButtonUrlUK,
    shirtBuyButtonTextUS,
    shirtBuyButtonUrlUS,
    appleText,
    appleButtonText,
    appleButtonUrl,
  } = config

  return (
    <Fragment>
      <Spacer height={25}/>

      <SmallWrapper alignment="center">
        <Image src={appleVille} alt="appleVille" width="400px" />
      </SmallWrapper>

      <VideoContainer/>

      <Spacer height={125}/>

      <FullWidthWrapper>
        <IdLink id={'donate'} />
        <HugeHeading align="center" color="white">
          Donate
        </HugeHeading>
        <H3 align="center" color="white" style={{ maxWidth: 500 }}>
          All proceeds from
            <a href="https://pcmusic.bandcamp.com/album/appleville-golden-ticket" target="_blank" rel="noreferrer">
              <Image src={ticket} alt="appleVille" width="50px" style={{verticalAlign: 'middle'}}/>
              </a>
            sales will be donated to Mermaids & the Black Cultural Archives
        </H3>
        <H3 align="center" color="white" style={{ maxWidth: 500 }}>
          Individual donations can be made via the links below ♥
        </H3>

        <Spacer height={25}/>

        <SmallWrapper>
        <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
          <a style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}} href="https://www.justgiving.com/blackculturalarchives"  target="_blank" rel="noreferrer">
            <Image style={{filter: 'invert(1)'}} src={BCA} alt="appleVille" width="100px" />
            <GreenButton>Black Cultural Archives</GreenButton>
          </a>


          <a style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}} href="https://mermaidsuk.org.uk/donate/" target="_blank" rel="noreferrer">
            <Image style={{filter: 'invert(1)'}} src={MermaidsLogo} alt="appleVille" width="100px" />
            <GreenButton>Mermaids UK</GreenButton>
          </a>
        </div>
        </SmallWrapper>
      </FullWidthWrapper>

      <Spacer />

      <GifContainer/>

      <Spacer height={150}/>

      <FullWidthWrapper  style={{ border: '5px groove white'}}>
        <IdLink id={'artists'} />
        <HugeHeading align="center" color="white">
          {artistTitle}
        </HugeHeading>
        <SmallWrapper>
          <Artists />
        </SmallWrapper>
      </FullWidthWrapper>


      <Spacer height={125}/>

      <SmallWrapper alignment="center">
        <IdLink id={'shirt'} />
        <HugeHeading align="center" color="white">
          {shirtTitle}
        </HugeHeading>
        <Image src={whiteshirt} alt="whiteshirt" width="400px" />
        <Image src={blueshirt} alt="blueshirt" width="400px" />
        <H3 align="center" color="white" style={{ maxWidth: 500 }}>
          {shirtText}
        </H3>
        <ButtonContainer>
          <a href={shirtBuyButtonUrlUK} target="_blank" rel="noreferrer">
            <GreenButton flat>{shirtBuyButtonTextUK}</GreenButton>
          </a>
          <a href={shirtBuyButtonUrlUS} target="_blank" rel="noreferrer">
            <GreenButton flat>{shirtBuyButtonTextUS}</GreenButton>
          </a>
        </ButtonContainer>
      </SmallWrapper>

      <Spacer height={125}/>

      <SmallWrapper alignment="center">
        <IdLink id={'shirt'} />
        <H3 align="center" color="white" style={{ maxWidth: 500 }}>
          {appleText}
        </H3>
        <Image src={apple} alt="apple" width="400px" />
        <a href={appleButtonUrl} target="_blank" rel="noreferrer">
          <GreenButton flat>{appleButtonText}</GreenButton>
        </a>
      </SmallWrapper>

      <Spacer height={225}/>

      <SmallWrapper alignment="center">
        <Image src={appleVille} alt="appleVille" width="400px" style={{transform: 'rotate(-180deg)'}}/>
      </SmallWrapper>

      <Spacer height={225} />
      <SmallWrapper alignment="center">
        <a href={'https://hifilabs.co'} target="_blank" rel="noreferrer">
          <H4 align="center" color="white" style={{ maxWidth: 500 }}>
            powered by <Image src={hifilabs} alt="appleVille" width="125px" style={{ verticalAlign: 'middle '}}/></H4>
        </a>
      </SmallWrapper>
    </Fragment>
  )
}

export default function IndexPage({ data, location }) {
  return (
    <Layout location={location}>
      <TitleAndMetaTags />
      <IndexPageContent data={data} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query homeQuery {
    markdownRemark(fields: { id: { eq: "home" } }) {
      ...PageMetaFragmentMarkdown
    }
    config: configYaml {
      dateRow
      whereRow

      ticketButton
      shirtButton

      artistTitle

      ticketTitle
      ticketText
      ticketBuyButtonText
      ticketBuyButtonUrl

      shirtTitle
      shirtText
      shirtBuyButtonTextUK
      shirtBuyButtonUrlUK
      shirtBuyButtonTextUS
      shirtBuyButtonUrlUS

      appleTitle
      appleText
      appleButtonText
      appleButtonUrl
    }
  }
`
