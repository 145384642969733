import React, { useState } from 'react'

import { gifStream } from '../streams/gifs'
import { postGif } from '../firebase/backend'
import { useStream } from '../streams/utils'
import gifs from '../data/gifs.json'

import './GifContainer.css';

const queries = Object.keys(gifs)

export function GifContainer() {
  const [type, setType] = useState('apple');
  const [selectedImage, setSelectedImage] = useState();
  const [results, setResults] = useState(gifs['apple']);
  const [sending, setSending] = useState(false);
  const [buttonText, setButtonText] = useState('POST');
  const lastGif = useStream(gifStream(), []);

  function renderResult(img, index) {
    const url = `https://web.archive.org/web/${img}`
    return (
      <li key={img} onKeyDown={() => {}} onClick={() => selectGif(url)} role="presentation">
        <img
          alt={`gif #${index}`}
          loading="lazy"
          key={index}
          className={url === selectedImage ? 'active' : ''}
          src={url}
        />
      </li>
    )
  }

    function renderLast(img, index) {
    return (
      <li key={img.id}>
        <img alt={`active gif #${index}`} loading="lazy" src={img.url} />
      </li>
    )
  }

  const selectGif = (url) => {
    setSelectedImage((previousUrl) => {
      if (previousUrl === url) return ''
      else return url
    })
  }

  const setGifType = async (type) => {
      setType(type)
      setResults([])
    await new Promise((resolve) => setTimeout(resolve, 500));
      setResults(gifs[type])
  }

  const addGif = async () => {
    if (sending) return;
    setSending(true)
    await postGif(selectedImage);
    setButtonText('(50s)');
    await new Promise((resolve) => setTimeout(resolve, 10000));
    setButtonText('(40s)');
    await new Promise((resolve) => setTimeout(resolve, 10000));
    setButtonText('(30s)');
    await new Promise((resolve) => setTimeout(resolve, 10000));
    setButtonText('(20s)');
    await new Promise((resolve) => setTimeout(resolve, 10000));
    setButtonText('(10s)');
    await new Promise((resolve) => setTimeout(resolve, 10000));

    setSending(false)
    setButtonText('POST')
    setSelectedImage()
  }
  
  console.log('gif')

  return (
      <div className="pixelated-gradient">
        <ol className="last">
          { lastGif.map((renderLast))}
        </ol>
        <section className="gif-container">
          <div style={{position: 'relative'}}>
              {queries.map(q => <button key={q} className={q === type ? 'active' : ''} onClick={() => setGifType(q)}>{q}</button>)}
              <button disabled={!selectedImage || sending} className="search" onClick={addGif}>{buttonText}</button>
          </div>
          <ol>
            {results.map(renderResult)}
          </ol>
            <img alt="internet archive logo" className="internet-archive" src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/84/Internet_Archive_logo_and_wordmark.svg/1054px-Internet_Archive_logo_and_wordmark.svg.png" />
        </section>
      </div>
  )
}
