import firebase from 'firebase/app';
import 'firebase/firebase-firestore';
import 'firebase/firebase-auth';

let instance;
let isDev = false;
let firebaseConfig = {
  apiKey: "AIzaSyBsn0CpNm_bDcu8G3NQ0BFaaR5kV6Sx6P4",
  authDomain: "hifilabs-agcook.firebaseapp.com",
  databaseURL: "https://hifilabs-agcook.firebaseio.com",
  projectId: "hifilabs-agcook",
  storageBucket: "hifilabs-agcook.appspot.com",
  messagingSenderId: "897127318427",
  appId: "1:897127318427:web:c1d99b7612bcc8517b573e",
  measurementId: "G-YGLZHMY1W0"
};

// if (typeof window !== 'undefined') {
//   if (window.location.hostname === 'localhost') {
//     isDev = true
//     firebaseConfig = {
//       projectId: "hifilabs-agcook",
//       apiKey: "AIzaSyBsn0CpNm_bDcu8G3NQ0BFaaR5kV6Sx6P4",
//     };
//   }
// }

export default function getFirebase() {
  if (typeof window !== 'undefined') {
    if (instance) return instance;
    instance = firebase.initializeApp(firebaseConfig);

    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        console.log('already logged in')
      } else {
        firebase.auth().signInAnonymously();
        console.log('Creating anonymous user..')
      }
    });

    if (isDev) {
      firebase.firestore().settings({
        host: "localhost:8080",
        ssl: false
      });
    }

    return instance;
  }

  return null;
}
