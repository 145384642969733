import getFirebase from '../firebase/index'
import { shareReplay, map } from 'rxjs/operators'
import moment from 'moment'

import { docData, ObservableFromRef } from './utils'

const Firebase = getFirebase();

const gifsStream = Firebase ? Firebase.firestore()
  .collection('gifs')
  .orderBy('ts', 'desc')
  .limit(3) : []

export const gifStream = () =>
  ObservableFromRef(gifsStream).pipe(
      map(snapshot => snapshot.docs),
      map(docs => docs.map(docData)),
      map(gifs => gifs.map(parser)),
      shareReplay(1)
    )

export const parser = rawEvent => ({
  ...rawEvent,
  ts: moment(rawEvent.ts),
  isAuthor: Firebase.auth().currentUser ? Firebase.auth().currentUser.uid === rawEvent.by : false,
})
