import React from 'react'

import './VideoContainer.css'

export const VideoContainer = () => {
  return (
    <div className="video-container">
      <div className="twitch">
        <div className="twitch-video">
          <iframe
            src="https://www.youtube.com/embed/?listType=playlist&list=PLt1djHoghA_YxQgx8ubUXFPiUcnvO_MRT"
            frameBorder="0"
            scrolling="no"
            allowFullScreen={true}
            height="100%"
            width="100%"
            title="twitch pcmusicinfo stream"
          ></iframe>
        </div>
      </div>
    </div>
  )
}
