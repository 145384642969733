import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'

export const ArtistsContainer = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  list-style-type: none;
  width: 90%;
  padding: 0;
`
const ArtistsElement = styled.li`
  width: 33%;
  margin: 10px 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.white};

  position: relative;
  font-size: 1.5em;
  transition: 200ms;

  ${({ theme }) => theme.media.largePhone`

    width: 50%;
  `};
`
const Name = styled.h2`
  font-weight: 600;

  margin: 5px 0;
  display: block;

  text-align: ${({ align }) => (align ? align : 'left')};
`

export function Artist({ artistName }) {
  return (
    <ArtistsElement key={artistName}>
      <Name align="center">{artistName}</Name>
    </ArtistsElement>
  )
}

export const Artists = () => {
  const { config } = useStaticQuery(graphql`
    query ArtistsQuery {
      config: configYaml {
        artists
      }
    }
  `)

  return (
    <React.Fragment>
      <ArtistsContainer>
        {config.artists.map((artistName, index) => (
          <Artist key={index} artistName={artistName} />
        ))}
      </ArtistsContainer>
    </React.Fragment>
  )
}
