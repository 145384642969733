import getFirebase from './index';
import moment from 'moment';

const Firebase = getFirebase();

export async function postSpamMessage(message) {
  return new Promise(async (resolve, reject) => {
    const currentUser = Firebase.auth().currentUser;

    if (message === '') return reject()

    try {
      await Firebase.firestore()
        .collection('spams')
        .doc()
        .set({
          message,
          by: currentUser.uid,
          ts: moment().format(),
        })
      resolve()
    } catch (error) {
      console.log(error)
      reject()
    }
  })
}

export async function postGif(url) {
  return new Promise(async (resolve, reject) => {
    const currentUser = Firebase.auth().currentUser;

    if (url === '') return reject()

    try {
      await Firebase.firestore()
        .collection('gifs')
        .doc()
        .set({
          url,
          by: currentUser.uid,
          ts: moment().format(),
        })
      resolve()
    } catch (error) {
      console.log(error)
      reject()
    }
  })
}

export async function postEmojiMessage(emojiMessage) {
  return new Promise(async (resolve, reject) => {
    const currentUser = Firebase.auth().currentUser;

    if (emojiMessage === '') return reject();

    try {
      await Firebase.firestore().collection('emojis').doc().set({
        message: emojiMessage,
        by: currentUser.uid,
        ts: moment().format(),
      });
      resolve();
    } catch (error) {
      console.log(error);
      reject();
    }
  });
}